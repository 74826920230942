<template>
<div>
    <br /><br /><br /><br /><br /><br /><a @click="getRecords()">record thumbs !</a>
    <br /><br /><br /><br /><br /><br /><a @click="getPoints()">point thumbs !</a>
</div>
<div>{{ results }}</div>
</template>

<script>


import lifeService from '../services/life_service'

import config from '../../config'

export default {

    data () {
        return {
            results: "",
            images: [],
            dummy: ""
        }
    },
    lifeservice: null,
    created() {
        this.lifeservice = new lifeService();
    },
    mounted() {
    },

    methods: {

        getRecords() {
            this.lifeservice.getLines().then(data => {
                data.forEach(d => this.getImagesForRecords(d.id))
            })
        },

        getPoints() {
            this.lifeservice.getLines().then(data => {
                data.forEach(d => this.getImagesForPoints(d.id))
            })
        },

        getImagesForRecords(lineid) {
            this.lifeservice.getPoints(lineid).then(data => {
                var vm = this
                data.forEach(async function(d) {
                    //this.images.push(d.recordmedia)
                    if (d.recordmedia && !d.recordthumb) { 
                        console.log(d)
                        const up = await fetch(`${config.images.server}/images/${d.recordmedia}?width=150&height=150&fit=contain&background=ffffff`, {method: 'GET'})
                        let string = ""
                        let buf = await up.arrayBuffer()
                        new Uint8Array(buf).forEach(
                            (byte) => { string += String.fromCharCode(byte) }
                            )            
                        d.recordthumb =   btoa(string)
                        vm.lifeservice.updatePoint(d)
                    }
                })

                this.results = 'done'
            })
        },

        getImagesForPoints(lineid) {
            this.lifeservice.getPoints(lineid).then(data => {
                var vm = this
                data.forEach(async function(d) {
                    //this.images.push(d.recordmedia)
                    if (d.pointthumb && d.pointthumb.length<100) { 
                        console.log(d)
                        const up = await fetch(`${config.images.server}/images/${d.pointthumb}?width=150&height=150&fit=contain&background=ffffff`, {method: 'GET'})
                        let string = ""
                        let buf = await up.arrayBuffer()
                        new Uint8Array(buf).forEach(
                            (byte) => { string += String.fromCharCode(byte) }
                            )            
                        d.pointthumb =   btoa(string)
                        vm.lifeservice.updatePoint(d)
                    }
                })

                this.results = 'done'
            })
        },

    }
}
</script>
